import React from "react";
import { navigate } from "gatsby";
import { Auth, Cache } from "aws-amplify";

import Button from "../../atomes/button";

const SignupBtn = ({
  errors,
  username,
  email,
  code,
  password,
  formState,
  setFormState,
  values,
}) => {
  const handelSignup = async e => {
    e.preventDefault();
    if (formState === "signup") {
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
        // validationData: [],  // optional
      })
        .then(data => {
          setFormState("confirmation");
        })
        .catch(err => console.error(err));
    }
    if (formState === "confirmation") {
      // After retrieveing the confirmation code from the user
      Auth.confirmSignUp(email, code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true,
      })
        .then(data => {
          Auth.signIn(email, password)
            .then(user => {
              Cache.setItem("userInfo", values);
              navigate("/App/dashboard");
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <Button
      type="primary"
      isDisabled={formState === "confirmation" ? false : errors.length !== 0}
      handelClick={handelSignup}
    >
      سجل
    </Button>
  );
};

export default SignupBtn;
