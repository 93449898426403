import React from "react";

import Login from "../components/organism/login";
import SignupBtn from "../components/molecules/signupBtn";

const Signup = ({ location }) => {
  return (
    <Login Buttons={SignupBtn} location={location} initFromState={"signup"} />
  );
};

export default Signup;
